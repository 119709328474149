import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

export default function SingleImageFullBlock({ content, objectPositionRight }) {
  const { image } = content
  const objectPositionRightClass = objectPositionRight
    ? 'object-position-right'
    : ''
  return (
    <section className='single-image-full-block'>
      <Inview className={`image fade-in ${objectPositionRightClass}`}>
        <ImageWrap image={image} />
      </Inview>
    </section>
  )
}
