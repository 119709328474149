import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'

export default function TextImageBlock({
  content,
  imageWider,
  isReversed,
  mobileImageFirst,
}) {
  const { title, body, image } = content
  const imageWiderClass = imageWider ? 'image-wider' : ''
  const isReversedClass = isReversed ? 'is-reversed' : ''
  const mobileImageFirstClass = mobileImageFirst ? 'mobile-image-first' : ''

  return (
    <section className='text-image-block pad-v-standard'>
      <div
        className={`container ${imageWiderClass} ${isReversedClass} ${mobileImageFirstClass}`}
      >
        <Inview className='text fade-in up'>
          <div className='wrap'>
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
            {body && (
              <div className='body'>
                <MarkdownWrap body={body} />
              </div>
            )}
          </div>
        </Inview>
        <Inview className={`image fade-in up`}>
          <ImageWrap image={image} />
        </Inview>
      </div>
    </section>
  )
}
