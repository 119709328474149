import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

export default function SingleImageBlock({ content, isBigger }) {
  const { image } = content
  const isBiggerClass = isBigger ? 'is-bigger' : ''
  return (
    <section className='single-image-block pad-v-standard'>
      <div className='container'>
        <Inview className={`image fade-in up ${isBiggerClass}`}>
          <ImageWrap image={image} />
        </Inview>
      </div>
    </section>
  )
}
